import { Pane, Text } from "evergreen-ui";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { ReactComponent as HangerIcon } from "../../assets/hanger.svg";
import { capitalizeFirstLetter, imageExists } from "../../utils/helpers";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import toast from 'react-hot-toast';
import "react-loading-skeleton/dist/skeleton.css";
import "./Card.css";
function Card(props) {
  const { product, width, contentOverride, height, isLoading, autoplay } =
    props;
  const [renderImages, setRenderImages] = useState([]);
  const navigate = useNavigate();

  const updateImages = async (images) => {
    const validImages = [];
    // for (let i = 0; i < images.length; i++) {
    //   const image = images[i];
    //   const exists = await imageExists(image);
    //   console.log("exists", exists)
    //     validImages.push(image)

    // }
    setRenderImages(images);
  };

  useEffect(() => {
    if (product === undefined || product.id === undefined) {
      return;
    }
    updateImages(images);
  }, [product]);
  if (product.id === null || product.id === undefined) {
    return;
  }
  const { images, brand, id, blur } = product;

  const addToCart = () => {
    let currentCart = localStorage.getItem("cart");
    if (currentCart !== null) {
      currentCart = JSON.parse(currentCart);
    } else {
      currentCart = [];
    }
    const hasSelected = currentCart.filter(item => item.id === product.id)
    if (hasSelected.length === 0) {
      currentCart.push({id: product.id, thumbnail: images[0]});
      localStorage.setItem("cart", JSON.stringify(currentCart));
    } 
    toast.success("Added to your fitting room")

   
  };

  return (
    <Pane
      background="#f6f6f6"
      float="left"
      elevation={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      margin={0}
      style={{
        borderRadius: 10,
        width: width || 250,
        ...(height && { height: height }),
        textAlign: "center",
        verticalAlign: "middle",
        ...(brand && { cursor: "pointer" }),
      }}
      onClick={() => {
        addToCart()
      }}
    >
      <div style={{ width: "95%", marginTop: 5, position: "relative" }}>
        <HangerIcon
          style={{
            bottom: 55,
            position: "absolute",
            height: 50,
            width: 50,
            zIndex: 100,
            right: 10,
            filter: "drop-shadow(rgba(0, 0, 0, 0.5) 0.1rem 0.25rem 0.25rem)",
          }}
        />
        <Slide
          indicators={renderImages.length > 1 && !props.disableIndicators}
          prevArrow={<></>}
          nextArrow={<></>}
          canSwipe={renderImages.length > 1}
          autoplay={renderImages.length > 1 && autoplay}
        >
          {renderImages.map((image) => {
            return (
              <img
                key={image}
                width="100%"
                src={image}
                style={
                  blur
                    ? { filter: "blur(3px)", borderRadius: 10 }
                    : { borderRadius: 10 }
                }
              />
            );
          })}
          {renderImages.length === 0 && (
            <>
              <div
                style={{
                  position: "absolute",
                  textAlign: "center",
                  transform: "translateY(-50%) translateX(-50%)",
                  top: "50%",
                  left: "50%",
                  zIndex: 10000,
                }}
              >
                {contentOverride}
              </div>
              <Skeleton style={{ height: height * 0.95, marginBottom: 15 }} />
            </>
          )}
        </Slide>

        {brand && (
          <div
            style={{
              height: 30,
              fontFamily: "sans-serif",
              textAlign: "left",
              marginLeft: 5,
              marginTop: 10,
            }}
          >
            {capitalizeFirstLetter(brand)}
          </div>
        )}
      </div>
    </Pane>
  );
}

export default memo(Card);
