import { Button, CornerDialog, Heading, Pane, RadioGroup, SelectMenu, TextInput } from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../contexts/Loading";
import UserContext from "../../contexts/User";
import {
  doc,
  getDocs,
  collection,
  query,
  limit,
  where,
  orderBy,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import Card from "./Card";
import { capitalizeFirstLetter } from "../../utils/helpers";
function Catalog(props) {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  const { user, authed } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [showPreference, setShowPreference] = useState(false);
  const [preferSex, setPreferSex] = useState("male");

  const fetchProducts = async () => {
    console.log("selectedCategory", selectedCategory);
    const productsRef = collection(db, "catalog");
    let q;
    if (selectedCategory === "All") {
      q = query(
        productsRef,
        where("traced", "==", true),
        where("lora_model", "!=", null),
        where("show", "==", true),
        orderBy("lora_model"),
        limit(50)
      );
    } else {
      q = query(
        productsRef,
        where("category", "==", selectedCategory.toLowerCase()),
        where("traced", "==", true),
        where("lora_model", "!=", null),
        where("show", "==", true),
        orderBy("lora_model"),
        limit(50)
      );
    }
    const querySnapshot = await getDocs(q);
    setProducts(querySnapshot.docs.map((doc) => doc.data()).filter(doc => doc.sex === preferSex || preferSex === "both"));
  };
  useEffect(() => {
    fetchProducts();
  }, [selectedCategory, preferSex]);

  useEffect(() => {
    console.log("user", user);
    const setupRedirect = window.location.search.includes("setup=complete");
    if (user.uid && user.isSetUp !== true && !setupRedirect) {
      navigate("/setup");
    }
    else if (user.uid && !user.processed) {
      navigate("/processing")
    }
    
    if (user.isSetUp && (user.clothing_preference === "" || user.clothing_preference === null || user.clothing_preference === undefined)) {
      setShowPreference(true);
    }
    else if (!(user.clothing_preference === "" || user.clothing_preference === null || user.clothing_preference === undefined)) {
      setPreferSex(user.clothing_preference)
    }
  }, [user]);

  const updateUserClothPreference = () => {
    const newUser = user.originalDBData;
    newUser.clothing_preference = preferSex;
    user.clothing_preference = preferSex;
    updateDoc(doc(db, "users", user.uid), {...newUser});
    setShowPreference(false);

  }
  if (!user.processed) {
    return null
  }

  return (
    <div
      style={{
        height: "100%",
        width: "93%",
        margin: '0 auto',
        background: "white",
        overflow: "scroll",
      }}
    >
      <CornerDialog
        title="Clothing Preference"
        isShown={showPreference}
        onCloseComplete={() => setShowPreference(false)}
        confirmLabel="Submit"
        hasCancel={false}
        hasClose={false}
        onConfirm={updateUserClothPreference}
      >
        To help us pre-generate clothes for you, what type of clothes are you interested in?
        <RadioGroup
      label="Sex"
      size={16}
      value={preferSex}
      options={[{ label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Both', value: 'both' }]}
      onChange={event => setPreferSex(event.target.value)}
    />
      </CornerDialog>

     <h1 style={{textTransform: 'uppercase', fontSize: '6em', fontWeight: 100, marginBottom: 20}}>find your fit</h1>
     <hr/>
     <div style={{textTransform: 'uppercase', marginBottom :50, fontFamily: 'sans-serif'}}>find the style that suits your lifestyle</div>
      <div style={{ width: "100%", display: "inline-block" }}>
      {["All", "Shirt", "Sweatshirt", "Blouse", "Top"].map((label) => (<Button style={{
        width: 100,
        fontFamily: 'sans-serif',
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        marginRight: 5,
        border: 'none'

      }} onClick={() => setSelectedCategory(label)}>{label} +</Button>))}
      </div>
<br/>
<div></div>
<br/>
      <Pane
        style={{ width: '100%', margin: '0 auto', rowGap: 20 }}
        display="flex"
        flexDirection="column"
        flexFlow="wrap"
        justifyContent="space-around"
        
      >
        {products.map((product, index) => {
          console.log("product", product);
          return (<Card key={`${product.name}-${index}`} product={product} autoplay={false} disableIndicators={true}/>);
        })}
      </Pane>
    </div>
  );
}

export default Catalog;
