import { Button, Link, Text } from "evergreen-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../utils/helpers";
import "./NavBar.css";
import {ReactComponent as HomeIcon} from "../assets/home.svg"
import {ReactComponent as FittingRoomIcon} from "../assets/fittingroom.svg"
import { useContext } from "react";
import { isMobile } from "react-device-detect";


export default function NavBar(props) {
  const navigate = useNavigate();
  const location = useLocation()
  console.log("location", location.pathname)
  const iconSize = isMobile ? 50 : 70
  return (
    <nav
      style={{ height: 60, width: "95%", zIndex: 1000000, marginTop: 10, marginLeft: 'auto', marginRight: 'auto' }}
    >
      <ul>
        <li style={{cursor: 'pointer'}}>
          <div style={{fontSize: 30}} onClick={() => window.location.href = "/"}>VERSIPEL</div>
        </li>
       {props.authed && location.pathname !== "/setup" && location.pathname !== "/" && location.pathname !== "/processing" &&  <li style={{marginLeft: isMobile ? 0 : '35vw'}}>
      <HomeIcon style={{height: iconSize, width: iconSize, color: location.pathname === "/" || location.pathname === "/catalog" ? "black" : "#C1C1C1", cursor: "pointer"}} onClick={() => navigate("/catalog")}/>
      <FittingRoomIcon style={{height: iconSize, width: iconSize, marginBottom: -5, cursor: "pointer", color: location.pathname === "/fitting" ? "black" : "#C1C1C1",}} onClick={() => navigate("/fitting")} />
      </li>}

        {props.authed && (
          <li style={{ float: "right" }}>
            <div>
              <Button
                appearance="minimal"
                style={{ marginTop: 10 }}
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </li>
        )}
        {!props.authed && (
          <li style={{ float: "right" }}>
            <div>
              <Button
                appearance="minimal"
                style={{ marginTop: 10 }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
}
